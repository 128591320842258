/* About.css */
.about {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  font-family: sans-serif;
  font-weight:lighter
}

.container {
  max-width: 1200px;
  margin: auto;
  font-family: sans-serif;
  font-weight: lighter;
}

.content {
  display: flex;
  flex-direction: row; /* Ensures the flex items are laid out horizontally */
  align-items: center; /* Aligns items vertically in the center */
  justify-content: space-between; /* Adds space between the image and text */
}

.image-section {
  flex: 1; /* Assigns a flex grow factor to the image section */
  padding-right: 20px;
}

.text-section {
  flex: 2; /* Allows the text section to take twice the space of the image section */
}

.image-section img {
  width: 100%; /* Makes the image responsive */
  height: auto;
}
