/* src/components/Header.css */
.header {
    background-color: #fff;
    padding: 50px 0;
  }
  
  .header-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
  }
  
  .header-text {
    max-width: 50%;
  }
  
  .header h1 {
    font-family: sans-serif;
    font-weight: lighter;
    font-size: 48px;
    margin-bottom: 20px;
    font-weight:lighter;
  }
  
  .header p {
    font-size: 18px;
    margin-bottom: 20px;
    font-weight:lighter;
  }
  
  .learn-more-btn {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #c83f8c;
    border: none;
    text-decoration: none;
    cursor: pointer;
    font-weight:lighter;
    border-radius: 5px;
  }
  
  .header-image img {
    max-width: 100%;
    height: auto;
  }
  