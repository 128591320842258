.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #fff;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .navbar-logo img {
    height: 40px;
  }
  
  .navbar-links a {
    margin: 0 15px;
    font-weight: 500;
    color: #333;
  }
  