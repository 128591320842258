/* src/components/Footer.css */
.footer {
    text-align: center;
    padding: 20px 0;
    border-top: 1px solid #e0e0e0;
    background-color: #fff;
     font-Family: 'sans-serif';
    font-Weight: 'lighter';
  }
  
  .footer .footer-links a {
    margin: 0 15px;
    color: #333;
    font-Family: 'sans-serif';
    font-Weight: 'lighter';
  }

  .footer .footer-links div{
    font-family: sans-serif;
    font-weight:lighter;
  }
  
  .a{
    font-family: sans-serif;
    font-weight:lighter;
  }
  .div{
    font-family: sans-serif;
    font-weight:lighter; 
  }