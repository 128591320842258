/* src/global.css */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&family=Playfair+Display:wght@700&display=swap');

body {
  margin: 20px;
  font-family: sans-serif;
  color: #333;
  background-color: #ffffff;
}

a {
  text-decoration: none;
  color: inherit;
  background-color: #ffffff;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  background-color: #ffffff;
}
